import { getValueOfKeyValue } from '@/utils/api'
import { TableLimit } from 'vue'

export class LoginData {
    notice: string | undefined
    result: string | undefined
    username: string | undefined
    sid: string | undefined
    userPoint!: number
    userLimitList!: Array<TableLimit>
    vipDesks: number[] = []
    bacDesks: number[] = []
    draDesks: number[] = []
    xmRate = 0
    static Parse(dataStr: string): LoginData {
        const result = new LoginData()

        const keyValues = dataStr.split('&')
        let uPoint

        keyValues.forEach((item: string) => {
            if (item.startsWith('notice=')) {
                result.notice = getValueOfKeyValue(item)
            } else if (item.startsWith('result=')) {
                result.result = getValueOfKeyValue(item)
            } else if (item.startsWith('username=')) {
                result.username = getValueOfKeyValue(item)
            } else if (item.startsWith('sid=')) {
                result.sid = getValueOfKeyValue(item)
            } else if (item.startsWith('UserPoint=')) {
                uPoint = getValueOfKeyValue(item)
                if (uPoint !== undefined) {
                    result.userPoint = parseInt(uPoint, 0)
                } else {
                    result.userPoint = 0
                }
            } else if (item.startsWith('xianhongArr=')) {
                const limits = getValueOfKeyValue(item)
                if (limits !== undefined) {
                    const xhValues = limits.split('$')
                    result.userLimitList = new Array<TableLimit>()
                    xhValues.forEach((xhValue) => {
                        const values = xhValue.split('#')
                        const limitInfo: TableLimit = {
                            Id: parseInt(values[0]),
                            Min: parseInt(values[1]),
                            Max: parseInt(values[2])
                        }
                        result.userLimitList.push(limitInfo)
                    })
                }
            } else if (item.startsWith('deskArr=')) {
                const _valTables = getValueOfKeyValue(item)
                if (_valTables) {
                    const _arrTables = _valTables.split(';')
                    const _bacTables = _arrTables[0].split(',')
                    const _vipTables = _arrTables[1].split(',')
                    const _dratables = _arrTables[2].split(',')
                    const _tables: number[][] = [[], [], [], []]

                    _bacTables.forEach((_b) => {
                        _tables[0].push(parseInt(_b))
                    })
                    _vipTables.forEach((_b) => {
                        _tables[1].push(parseInt(_b))
                    })
                    _dratables.forEach((_b) => {
                        _tables[2].push(parseInt(_b))
                    })
                    result.bacDesks = _tables[0]
                    result.vipDesks = _tables[1]
                    result.draDesks = _tables[2]
                }
            } else if (item.startsWith('xmRate=')) {
                result.xmRate = parseInt(item.split('=')[1]) ?? 0
            }

            // deskArr=1,2,3,4,5,6,10;23,24,25,26,27,28;7,8,9,12,13
        })
        return result
    }
}
