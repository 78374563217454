
import {
    computed,
    defineComponent,
    Emitter,
    inject,
    onBeforeMount,
    ref,
    UserSessions
} from 'vue'
import { iconService } from '@/components/icon'
import { useI18n } from 'vue-i18n'
import { useStore } from '@/store'
import { AppActions, AppMutation } from '@/store/types'
import { ACT, EventBuss, languageOptions, OtherApiParams } from '@/types/global'
import { getValueByKey } from '@/utils/api'
import { LoginData } from '@/types/LoginData'
import { useRouter } from 'vue-router'
import { ROUTES } from '@/router'
import { generateRandomNumber } from '@/utils'
import { MessageBoxActionType, MessageBoxEvent } from '@/types/MessageBox'

export default defineComponent({
    components: {
        // 'icon-arrow-down': iconArrowDown,
        'icon-service': iconService
    },
    setup() {
        const store = useStore()
        const router = useRouter()
        const emitter = inject('emitter') as Emitter
        const { t, locale } = useI18n()
        const langArr = ref(languageOptions)

        const message = ref('')
        const showLanguageOptions = ref(false)

        const uname = ref('')
        const pwd = ref('')
        const captcha = ref('')

        const capchaInput = ref<string>(`${generateRandomNumber(1000, 9999)}`)

        onBeforeMount(() => {
            if (serverLine.value <= 0 && serverLine.value !== -1) {
                router.push({ name: ROUTES.SERVER_SELECTION })
                return
            }
        })

        const accountLogin = () => {
            if (uname.value.length === 0) {
                message.value = t('login.pleaseenterusername')
                return
            }
            if (pwd.value.length === 0) {
                message.value = t('login.pleaseenterpwd')
                return
            }

            if (captcha.value.toString() !== capchaInput.value) {
                message.value = t('login.invalidcode')
                return
            }

            message.value = ''

            const params = {
                ...OtherApiParams,
                act: ACT.LOGIN,
                username: uname.value,
                pwd: pwd.value
            }

            requestLogin(params)
        }

        const requestLogin = (params: any) => {
            store.commit(AppMutation.SHOW_LOADING)
            store
                .dispatch(AppActions.FETCH_DATA, params)
                .then((response: string) => {
                    store.commit(AppMutation.HIDE_LOADING)

                    if (response.includes('err=')) {
                        // show errors
                        const errorCode = getValueByKey(response, 'err')
                        if (errorCode === '-1')
                            message.value = t('login.invalidparams')
                        else if (errorCode === '-2')
                            message.value = t('login.invaliduserorpassword')
                        else if (errorCode === '-3')
                            message.value = t('login.accountislocked')
                        else if (errorCode === '0')
                            message.value = t('login.unknownerror')
                        else {
                            // -4
                            message.value = t('login.accountisalreadylogedin')
                        }
                        emitter.emit(EventBuss.TOAST_MESSAGE, message.value)
                    } else {
                        message.value = ''

                        let {
                            sid,
                            username,
                            userPoint,
                            userLimitList,
                            notice,
                            vipDesks,
                            bacDesks,
                            draDesks,
                            xmRate
                        } = LoginData.Parse(response)

                        if (!username) username = uname.value

                        const userSession: UserSessions = {
                            token: String(sid),
                            username: String(username),
                            point: userPoint,
                            limits: userLimitList,
                            notice: String(notice),
                            bacDesks,
                            vipDesks,
                            draDesks,
                            xmRate
                        }

                        store.commit(
                            AppMutation.INIT_USER_SESSTION,
                            userSession
                        )
                        router.push({ name: ROUTES.LOBY })
                    }
                })
                .catch(() => {
                    store.commit(AppMutation.HIDE_LOADING)
                    emitter.emit(
                        EventBuss.TOAST_MESSAGE,
                        t('prompt.networkerror')
                    )
                })
        }

        const demoLogin = () => {
            const params = { ...OtherApiParams, act: ACT.TRIAL }
            requestLogin(params)
        }

        const onCapchaKeydown = (event: KeyboardEvent) => {
            if (event.key.toLowerCase() === 'enter') {
                // request()
                event.preventDefault()
                return
            }

            if (event.key.toLowerCase() === 'e') {
                event.preventDefault()
                return
            }

            if (
                document.getSelection()?.toString() === captcha.value.toString()
            ) {
                return
            }

            if (
                captcha.value.toString().length > 3 &&
                event.key.toLowerCase() !== 'backspace'
            ) {
                event.preventDefault()
                return
            }
        }

        const contactService = () => {
            window.open(process.env.VIP_SERVICE, '_blank')
        }

        const selectLanguage = () => {
            const ev: MessageBoxEvent = {
                type: MessageBoxActionType.open,
                name: 'lang'
            }
            emitter.emit(EventBuss.DIALOG, ev)
        }

        const language = computed((): string => {
            const result = langArr.value.find((x) => x.code === lang.value)
            return result?.name || ''
        })

        const lang = computed(() => store.getters['lang'])
        const serverLine = computed((): number => store.getters['serverLine'])

        return {
            capchaInput,
            pwd,
            uname,
            lang,
            language,
            langArr,
            message,
            captcha,
            showLanguageOptions,
            contactService,
            selectLanguage,
            accountLogin,
            t,
            demoLogin,
            onCapchaKeydown
        }
    }
})
